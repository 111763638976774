import store from '@/store'
import Vue from 'vue'


function hasTierPermissions(...requiredTier) {

    if (!requiredTier || requiredTier.length === 0) {
        return true
    }

    const clientData =  store.state.client.clientData

    return clientData && requiredTier.includes(clientData.tier)
}

Vue.prototype.$hasTierPermission = hasTierPermissions